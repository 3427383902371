@import '~@wk/fundamentals/dist/grid.min.css';
@import '~@wk/components/dist/form.min.css';
@import '~@wk/components/dist/button.min.css';
@import '~@wk/components/dist/tag.min.css';

@media (min-width: 48rem) and (max-width: 64rem) {
    .wk-column-6-laptop {
        flex-basis: 50%;
        max-width: 50%;
        min-width: 50%;
    }

    .wk-column-12-laptop {
        flex-basis: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .wk-row {
        flex-wrap: wrap;
    }
}

@media (max-width: 37.5rem) {
    .wk-column-12 {
        flex-basis: 100%;
        max-width: 100%;
        min-width: 100%;
    }
}

.migration-overview-view {
    .page-header {
        padding: 20px;
        background-color: #F6F6F6;
        border: 1px solid #BFBFBF;

        .title-section {    
            color: #353535;
            font-size: 20px;
            font-weight: 500;
        }
    
        .wk-row > div {
            padding: 10px;
            max-width: 20.66%;
        }
    }

    .page-content {
        padding: 15px 25px 0 20px;
        min-height: 475px;

        .filter-section {
            display: flex;
            justify-content: space-between;

            & > div {
                padding: 0;
            }

            input[type=search] {
                height: 32px;
                color: #474747;
                font-size: 14px !important;
            }

            .wk-icon-search {
                font-size: 16px;
            }

            .right-section {
                display: flex;
                flex-wrap: wrap;
                align-content: baseline;
                grid-template-areas: auto auto;
                justify-content: flex-end;

                button {
                    height: 32px;
                    width: 124px;
                    font-size: 14px;
                    padding: 7px 13px;
                    line-height: 18px;
                    background-color: #005b92;
                    letter-spacing: 0;

                    &:focus {
                        outline-color: #000;
                        outline-offset: 0;
                        outline-style: solid;
                        outline-width: .125rem;
                    }

                    &:hover {
                        background-color: #003d61;
                    }
                }

                @media (max-width: 37.5rem) {
                    & {
                        justify-content: flex-start;
                    }
                }

                .filter {
                    cursor: pointer;
                    display: inline;
                    color: #005B92;
                    padding: 5px .625rem 5px 18px;
                    font-size: 14px;

                    .wk-icon-funnel {
                        margin-left: 4px;
                    }

                    .filter-text {
                        font-size: 14px;
                        line-height: 18px;
                        margin-right: 2px;
                        margin-left: 6px;
                    }

                    .wk-tag {
                        margin-left: 5px;
                        font-family: 'Fira Sans Medium', sans-serif;
                        font-size: 11px;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
}