.status-button {
    display: grid;
    box-shadow: 2px 2px 8px 0 rgba(0,0,0,0.2);
    cursor: pointer;
    height: 64px;
    box-sizing: content-box;

    &.is-active {
        border: 2px solid #005B92;
        height: 60px;

        .button-content {
            padding: 14px 10px;
        }

        .count {
            line-height: 60px;
        }
    }

    .count {
        color: #353535;
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 2.5px;
        text-align: center;
        background-color: rgb(242, 248, 252);
        grid-column: 1;
        width: 80px;
        line-height: 64px;
    }

    .button-content {
        grid-column: 2 / 100;
        padding: 16px 10px;
        background-color: white;

        .icon {
            margin-right: 4px;
            float: left;
            line-height: 30px;
            font-size: 24px;

            .small-icon {
                font-size: 20px;
            }

            .bold-icon {
                font-weight: 600;
            }

            .wk-icon-check-circle {
                color: #85BC20;
            }

            .wk-icon-close-circle {
                color: #E5202E;
            }

            .wk-icon-clock {
                color: #474747;
            }

            .wk-icon-infinity {
                color: #EA8F00;
            }
        }
    }

    .status-texts {

        .title {
            color: #353535;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1.17px;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .description {
            color: #232323;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
