@import '~@wk/components/dist/header.min.css';

.wk-banner {
    position: fixed;

    .wk-dropdown {
        display: inline-block !important;
    }

    .wk-banner-product-name {
        color: #353535;
    }
}