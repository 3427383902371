@import '~@wk/components/dist/table.min.css';
@import '~@wk/components/dist/tooltip.min.css';

.table-section {
    overflow-x: auto;
    overflow-y: hidden;
    border-left: 0.0625rem solid #dadada;
    border-right: 0.0625rem solid #dadada;

    .icon-red {
        color: #e5202e;
    }

    .icon-green {
        color: #85BC20;
    }

    .icon-blue {
        color: #005b92;
    }

    .icon-dark {
        color: #474747
    }

    .icon-yellow {
        color: #EA8F00;
    }

    table {
        border-left: none;
        border-right: none;

        caption {
            display: none;
        }
    }

    th, td {
        border: .0625rem solid #dadada;

        &:first-of-type {
            border-left: none;
        }

        &:last-of-type {
            border-right: none;
        }

        label {
            vertical-align: text-top;
        }

        input[type=checkbox]:focus {
            & + span::before {
                box-shadow: none;
            }
        }

        .wk-field-choice[type=checkbox]:focus + .wk-field-choice-text::before {
            box-shadow: none;
        } 

        .wk-field-choice[type=checkbox]:enabled:checked + .wk-field-choice-text {
            &::before {
                background-color: #007AC3;
                border-color: #007AC3;
            }
        }

        .wk-field-choice-text {
            font-size: 14px;
            
            &::before {
                width: 18px;
                height: 18px;
            }

            &::after {
                width: 11px !important;
                height: 5px !important;
                border-width: 0 0 2px 2px !important;
            }
        }

        &.statuses > div {
            display: flex;
            justify-content: space-between;

            span[aria-hidden=true] {
                font-size: 24px;
                cursor: pointer;

                &.icon-bold {
                    font-weight: 600;
                }

                &.wk-icon-visible, &.wk-icon-download-line {
                    font-size: 16px;

                    & + div.wk-tooltip {
                        left: -196% !important;
                    }
                }

                &.wk-icon-visible {
                    display: none;
                }
            }

            & > div {
                position: relative;

                .wk-tooltip {
                    position: absolute;
                    left: -115%;
                    text-align: center;
                    width: 110px;

                    &.wk-position-bottom {
                        top: 175%;
                    }

                    &.wk-position-top {
                        bottom: 175%;
                    }
                }
            }
        }
    }
}