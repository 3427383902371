@import '~@wk/components/dist/pagination.min.css';

.wk-pagination-bar {
    padding: 10px 20px 10px 20px;
    border-top: .0625rem solid #dadada;
    margin-top: 10px;

    .wk-field {
        margin-bottom: 0;
    }

    .wk-pagination-link {
        cursor: pointer;
    }

    .disabled {
        pointer-events: none;
    }
}