@import '~@wk/components/dist/client-switcher.min.css';
@import '~@wk/components/dist/fishbowl.min.css';
@import '~@wk/components/dist/dropdown.min.css';

.wk-fishbowl-user-name {
    font-size: 15px;
}

.wk-button.wk-dropdown-toggle {
    background-color: transparent;
}

.wk-dropdown-menu {
    width: 100% !important;
    min-width: 100% !important;

    dl {
        overflow: hidden;

        span[aria-hidden=true] {
            vertical-align: text-bottom !important;
        }
    }
}