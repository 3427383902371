@import '~@wk/components/dist/spine.min.css';

.wk-spine-nav {
    height: calc(100vh - 56px);

    &.wk-is-spine-expanded-fixed {
        position: fixed !important;
    }

    .wk-spine-link-icon {
        font-size: 26px;
        margin-left: 0 !important;
    }
}

.wk-is-spine-expanded-fixed button.wk-button.wk-spine-activator {
    transform: translate(12.250rem);
}

@media (max-width: 47.9375rem) {
    .wk-spine-container .wk-spine-nav.wk-is-spine-expanded-fixed .wk-spine-activator {
        display: block;
    }

    .wk-is-spine-expanded-fixed button.wk-button.wk-spine-activator {
        transform: translate(12.375rem);
    }
}